(($) => {

  const galleries = [document.querySelector('.gallery-impact-stories'),document.querySelector('.gallery-testimonials')];
  //const galleries = [document.querySelector('.gallery-impact-stories')];

  galleries.forEach(gallery => {

    const type = gallery.dataset.type;
    const full_posts = document.querySelector(`.gallery__full-posts[data-type*=${type}]`);
    const screen =     full_posts.querySelector('.gallery__screen');
    const nav_jump = document.querySelector('.nav-jump');

    screen.addEventListener("click",() => {
      if (active) setActivePost(active,false);
    })
    nav_jump.addEventListener("click",() => {
      if (active) setActivePost(active,false);
    })

    let active = undefined;
    const posts = {};
    // const incrementActivePost = (amt) => {
    //   let pNew = active + amt;
    //   if (pNew < 0) {
    //     pNew = posts.length + pNew;
    //   } else if (pNew >= posts.length) {
    //     pNew = 0 + (pNew - posts.length);
    //   }
    //   setActivePost(pNew,true);
    // }

    const resetPost = post => {
      post.querySelector(".card__wrapper").scrollTop = 0;
      post.dispatchEvent(new CustomEvent("resetPost"));
    }

    const setActivePost = (id,on) => {
      if (posts[active]) posts[active].full.classList.remove("active");
      active = on ? id : null;
      if (on) {
        posts[id].full.classList.add("active");
        resetPost(posts[id].full);
        screen.classList.add("active");
        document.documentElement.classList.add("gallery-card-active");
      } else {
        posts[id].full.classList.remove("active");
        screen.classList.remove("active");
        document.documentElement.classList.remove("gallery-card-active");
      }
    }

    document.addEventListener("keydown",(e) => {
      e = e || window.event;
      var isEscape = false;
      if ("key" in e) {
          isEscape = (e.key === "Escape" || e.key === "Esc");
      } else {
          isEscape = (e.keyCode === 27);
      }
      if (isEscape && posts[active]) {
        setActivePost(active,false);
      }
    })
    

    const setCardHandlers = () => {

      const items = gallery.querySelectorAll('.gallery__item');

      Array.from(items).forEach((preview,p) => {
        const id = preview.dataset.post;
        const full = full_posts.querySelector(`.post__full[data-post="${id}"]`);
        posts[id] = {
          id:id,
          preview:preview,
          full:full
        };
        preview.addEventListener("click",() => {
          setActivePost(id,true);
        })
        full.querySelector(".card__close").addEventListener("click",() => {
          setActivePost(id,false);
        })
      })
    }

    setCardHandlers();

    const controls = Array.from(full_posts.querySelectorAll(".card__nav"));
    controls.forEach(control => {
      const prev = control.querySelector(".nav-prev a");
      if (prev) {
        const prevId = prev.dataset.rel;
        prev.addEventListener("click",(e) => {
          e.preventDefault();
          setActivePost(prevId,true);
        })
      }
      
      const next = control.querySelector(".nav-next a");
      if (next) {
        const nextId = next.dataset.rel;
        next.addEventListener("click",(e) => {
           e.preventDefault();
          setActivePost(nextId,true);
        })
      }
    })

    if (location.hash && location.hash.length > 1) {
      const hash = location.hash.substring(1,location.hash.length);
      const anchorPost = gallery.querySelector(`[data-anchor='${hash}']`);
      if (anchorPost) {
        const anchorPostId = anchorPost.dataset.post;
        if (posts[anchorPostId]) {
          history.pushState({},"","#discover");
          window.addEventListener("appInitialized",() => {
            setActivePost(anchorPostId,true);
          });
        }
      }
    }

  })


})(jQuery)